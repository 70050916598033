<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>意向信息</el-breadcrumb-item>
            <el-breadcrumb-item>每日服务人次</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="wzright" style="float:right; margin-top:-20px;">
                <el-button size="small" type="primary" icon="el-icon-circle-plus-outline" @click="openadd" v-if="list.length<3">添加信息</el-button>
            </div>
        </div>
        <div class="box" style="margin-top:30px;">
            <div class="list">
                 <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="nameOne"
                    label="名称1">
                    </el-table-column>
                    <el-table-column
                    prop="valueOne"
                    label="值1">
                    </el-table-column>
                    <el-table-column
                    prop="nameTwo"
                    label="名称2">
                    </el-table-column>
                    <el-table-column
                    prop="valueTwo"
                    label="值2">
                    </el-table-column>
                    <el-table-column
                    prop="valueMale"
                    label="男性比例">
                        <template slot-scope="scope">
                            {{scope.row.valueMale}}%
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="valueFemale"
                    label="女性比例">
                        <template slot-scope="scope">
                            {{scope.row.valueFemale}}%
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    width="100"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary"   @click="openedit(scope.row)">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <pop-up :title="status==1?'添加':'编辑'" width="35%" :isshow="showpop" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="form" :model="form" label-width="120px">
                    <el-form-item label="名称1">
                        <el-input v-model="form.nameOne"></el-input>
                    </el-form-item>
                    <el-form-item label="值1">
                        <el-input-number v-model="form.valueOne" controls-position="right"></el-input-number>
                    </el-form-item>
                    <el-form-item label="名称2">
                        <el-input v-model="form.nameTwo"></el-input>
                    </el-form-item>
                    <el-form-item label="值2">
                        <el-input-number v-model="form.valueTwo" controls-position="right"></el-input-number>
                    </el-form-item>
                    <el-form-item label="男性比例">
                        <el-input-number v-model="form.valueMale" controls-position="right"></el-input-number>
                    </el-form-item>
                    <el-form-item label="女性比例">
                        <el-input-number v-model="form.valueFemale" controls-position="right"></el-input-number>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import serverurl from '@/api/booth'
import _api from '@/api/index'
export default {
    data(){
        return{
            list:[],
            form:{id:null,nameOne:null,valueOne:null,nameTwo:null,valueTwo:null,valueMale:0,valueFemale:0},
            showpop:false,
            status:1,
        }
    },
    created(){
        this.getList()
    },
    methods:{
        getList(){
            _api.get(serverurl.registraTotal,{}).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.list=res.data
                }
            })
        },
        openedit(row){
            for(let k in this.form){
                this.form[k]=row[k]
            }
            this.status=2
            this.showpop=true
        },
        openadd(){
            this.showpop=true
        },
        getclose(){
            this.showpop=false
            this.form={id:null,nameOne:null,valueOne:null,nameTwo:null,valueTwo:null,valueMale:0,valueFemale:0}
            this.status=1
        },
        getconfirm(){
            let apitype=this.status==1?'post':'put'
            _api[apitype](serverurl.registraTotal,this.form).then(res=>{
                if(res.code==200){
                    this.$message.success("操作成功！")
                    this.getclose()
                    this.getList()
                }else{
                    this.$message.error(res.message)
                }
            })
        }
    }
}
</script>